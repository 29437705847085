import React, { useEffect, useState } from 'react';
import { Link, graphql, navigate } from 'gatsby';
import { useSelector } from 'react-redux';
// TODO: Gatsby Image - change to Static Image
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';

import { loader, authWrapper, imgBox, formBox, logo, header, help } from './auth.module.scss';

import SEO from '../components/seo';
import SubTitle from '../components/atoms/subtitle';
import Logo from '../assets/images/svg/logo.svg';
import LinkUnderline from '../components/atoms/link-underline';
import Main from '../layouts/main';
import ActivateAccount from '../components/organisms/auth/activate-account';
import LoginForm from '../components/organisms/auth/login-form';
import RegisterForm from '../components/organisms/auth/register-form';
import LostPasswordForm from '../components/organisms/auth/lost-password-form';
import ResetPasswordForm from '../components/organisms/auth/reset-password-form';
import Loader from '../components/atoms/loader';
import { getAbsolutePath, ORDER_ROUTES } from '../routes';
import useHasMounted from '../hooks/useHasMounted';

const forms = {
    login: LoginForm,
    register: RegisterForm,
    lostPassword: LostPasswordForm,
    resetPassword: ResetPasswordForm,
    activateAccount: ActivateAccount,
};

const Auth = ({ data, pageContext }) => {
    const { title, type } = pageContext;
    const Form = forms[type];
    const { userData, isUserVerificationFinished } = useSelector((state) => ({
        userData: state.user.userData,
        isUserVerificationFinished: state.user.isUserVerificationFinished,
    }));
    const { search } = useLocation();
    const [redirect, setRedirect] = useState(getAbsolutePath('APP_MY_ACCOUNT'));
    const hasMounted = useHasMounted();

    const returnTo = new URLSearchParams(search).get('returnTo');

    useEffect(() => {
        if (returnTo === 'order') {
            setRedirect(getAbsolutePath('ORDER', ORDER_ROUTES));
        }
    }, [returnTo]);

    if (userData?.email) {
        navigate(redirect);
        return null;
    }

    return (
        <>
            <SEO title={title} />
            <Main className={authWrapper} isPaddingEqualToHeader={true}>
                {(!isUserVerificationFinished || !hasMounted) && <Loader className={loader} />}
                {isUserVerificationFinished && hasMounted && (
                    <>
                        <section className={imgBox}>
                            <GatsbyImage
                                image={getImage(data.file)}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    height: '100%',
                                    width: '100%',
                                }}
                                imgStyle={{ objectPosition: 'top center' }}
                                alt="Tło logowania"
                            />
                        </section>
                        <section className={formBox}>
                            <Logo className={logo} />
                            <SubTitle className={header}>{title}</SubTitle>
                            {Form && <Form />}
                            <div className={help}>
                                Potrzebujesz pomocy?{' '}
                                <Link to="/kontakt">
                                    <LinkUnderline Tag="span" className={help}>
                                        Napisz do nas!
                                    </LinkUnderline>
                                </Link>
                            </div>
                        </section>
                    </>
                )}
            </Main>
        </>
    );
};

export const query = graphql`
    query {
        file(relativePath: { eq: "login-background.png" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        site {
            ...siteFields
        }
    }
`;

export default Auth;
