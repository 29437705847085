import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Link } from '@reach/router';
import { useFormik } from 'formik';

import { form, loginButton } from './reset-password-form.module.scss';
import { config } from '../../../config';
import { resetPassword } from '../../../communication/auth';

import Input from '../../atoms/form/input';
import Button from '../../atoms/button';
import ErrorForm from '../../atoms/form/error-form';
import Loader from '../../atoms/loader';
import Title from '../../atoms/title';

const errors = config.messages;

const readToken = (location) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('token');
};

const ResetPasswordForm = () => {
    const [isLoading, setLoading] = useState(false);
    const [apiErrors, setApiErrors] = useState([]);
    const [success, setSuccess] = useState(false);

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordRepeat: '',
            token: '',
        },
        validationSchema: Yup.object({
            token: Yup.string().required(errors.form.required),
            password: Yup.string().required(errors.form.required).min(8, errors.password.min),
            passwordRepeat: Yup.string()
                .required(errors.form.required)
                .oneOf([Yup.ref('password')], errors.password.repeat),
        }),

        onSubmit: async (formValues) => {
            setLoading(true);
            resetPassword(formValues.password, formValues.token)
                .then(() => {
                    setLoading(false);
                    setApiErrors([]);
                    setSuccess(true);
                    formik.resetForm();
                })
                .catch((error) => {
                    setLoading(false);
                    setApiErrors(error.response.data.messages);
                });
        },
    });

    useEffect(() => {
        formik.setFieldValue('token', readToken(window.location));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderForm = () => {
        if (isLoading) {
            return <Loader />;
        }

        return (
            <form className={form} onSubmit={formik.handleSubmit}>
                {apiErrors.map((error) => (
                    <ErrorForm key={error.content}>{error.content}</ErrorForm>
                ))}
                {formik.errors.token && (
                    <ErrorForm>Przepraszamy wystąpił błąd. Nie mogliśmy odczytać tokenu.</ErrorForm>
                )}
                <Input
                    name="password"
                    type="password"
                    placeholder="Nowe hasło"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    error={formik.touched.password ? formik.errors.password : null}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                />
                <Input
                    name="passwordRepeat"
                    type="password"
                    placeholder="Powtórz nowe hasło"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.passwordRepeat}
                    error={formik.touched.passwordRepeat ? formik.errors.passwordRepeat : null}
                />
                <Button type="submit" color="yellow" fullwidth={true}>
                    Zapisz hasło
                </Button>
            </form>
        );
    };

    return (
        <>
            {success ? (
                <>
                    <Title>Twoje hasło zostało zmienione.</Title>
                    <Link to="/login/" className={loginButton}>
                        <Button fullwidth={true}>Przejdź do logowania</Button>
                    </Link>
                </>
            ) : (
                renderForm()
            )}
        </>
    );
};

export default ResetPasswordForm;
