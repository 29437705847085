import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { form } from './lost-password-form.module.scss';
import { lostPassword } from '../../../communication/auth';
import { config } from '../../../config';

import Input from '../../atoms/form/input';
import Button from '../../atoms/button';
import ErrorForm from '../../atoms/form/error-form';
import Loader from '../../atoms/loader';
import Title from '../../atoms/title';

const formErrors = config.messages.form;

const LostPasswordForm = () => {
    const [isLoading, setLoading] = useState(false);
    const [apiErrors, setApiErrors] = useState([]);
    const [success, setSuccess] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email(formErrors.email).required(formErrors.required),
        }),

        onSubmit: async (formValues) => {
            setLoading(true);
            lostPassword(formValues.email)
                .then(() => {
                    setLoading(false);
                    setApiErrors([]);
                    setSuccess(true);
                    formik.resetForm();
                })
                .catch((error) => {
                    setLoading(false);
                    setApiErrors(error.response.data.messages);
                });
        },
    });

    const renderForm = () => {
        if (isLoading) {
            return <Loader />;
        }

        return (
            <form className={form} onSubmit={formik.handleSubmit}>
                <p>
                    Zapomniałeś hasła? Wpisz swoją nazwę użytkownika lub adres e-mail. Dostaniesz
                    link do stworzenia nowego hasła mailem.
                </p>
                {apiErrors.map((error) => (
                    <ErrorForm key={error.content}>{error.content}</ErrorForm>
                ))}
                <Input
                    name="email"
                    type="email"
                    placeholder="Adres e-mail"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email ? formik.errors.email : null}
                />
                <Button type="submit" color="yellow" fullwidth={true}>
                    Przypomnij hasło
                </Button>
            </form>
        );
    };

    return (
        <>
            {success ? (
                <Title>Wysłaliśmy link do resetowania hasła na Twój adres e-mail.</Title>
            ) : (
                renderForm()
            )}
        </>
    );
};

export default LostPasswordForm;
