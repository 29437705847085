import React, { useCallback, useEffect } from 'react';
import { Link, useLocation } from '@reach/router';

import { loginButton } from './reset-password-form.module.scss';

import { config } from '../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { activateUser, USER } from '../../../redux/user/user.actions';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';

import Button from '../../atoms/button';
import Loader from '../../atoms/loader';
import Title from '../../atoms/title';

const statusMap = config.apiStatusMap;

const ActivateAccount = () => {
    const dispatch = useDispatch();
    const status = useSelector((state) => selectLoaderByEntity(state, USER));
    const { search } = useLocation();
    const token = new URLSearchParams(search).get('token');

    const sendToken = useCallback(() => {
        if (token) {
            dispatch(activateUser(token));
        }
    }, [dispatch, token]);

    useEffect(() => {
        sendToken();
    }, [sendToken]);

    const renderSuccess = () => {
        return (
            <>
                <Title>Twoje konto zostało aktywowane.</Title>
                <Link to="/login/" className={loginButton}>
                    <Button fullwidth={true}>Przejdź do logowania</Button>
                </Link>
            </>
        );
    };

    const renderForm = () => {
        return (
            <Button type="submit" color="yellow" fullwidth={true} onClick={sendToken}>
                Tak. Chcę aktywować konto.
            </Button>
        );
    };

    switch (status) {
        case statusMap.success:
            return renderSuccess();
        case statusMap.loading:
            return <Loader />;
        case statusMap.fail:
        case statusMap.idle:
        default:
            return renderForm();
    }
};

export default ActivateAccount;
