import axios from 'axios';
import { config } from '../config';

export const lostPassword = (email) => {
    const payload = {
        email,
    };
    return axios.post(config.endpoints.lostPassword, payload);
};

export const resetPassword = (password, token) => {
    const payload = {
        password,
        token,
    };
    return axios.post(config.endpoints.resetPassword, payload);
};
