import React from 'react';

import { labelText, textarea, input } from './input.module.scss';

import FormControl from './form-control';
import Error from './error';

const Input = ({
    error,
    type = 'text',
    isTextArea = false,
    rows = 5,
    className = '',
    labelName,
    ...rest
}) => (
    <FormControl>
        {isTextArea ? (
            <>
                <h5 className={labelText}>{labelName}</h5>
                <textarea className={`${input} ${textarea} ${className}`} rows={rows} {...rest} />
            </>
        ) : (
            <>
                <h5 className={labelText}>{labelName}</h5>
                <input type={type} className={`${input} ${className}`} {...rest} />
            </>
        )}
        {error && <Error>{error}</Error>}
    </FormControl>
);

export default Input;
